import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import MyLink from "../MyLink/MyLink";
import AdminSwitch from "../AdminSwitch/AdminSwitch";
import { auth } from "../../firebase";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { useAuth } from "../../AuthProvider";
import { PageCountsContext } from "../../container/Pages/Intranet/PageCountsContext";

const Navbar = () => {
  const { isAdmin, userData } = useAuth();
  const pageCounts = useContext(PageCountsContext);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [loadingUser, setLoadingUser] = useState(true); // Add loading state
  const location = useLocation();
  const isMobile = window.innerWidth <= 670;
  const { setUserData } = useAuth();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      // Check if user is logged in
      setUserLoggedIn(!!user);
      setLoadingUser(false); // Set loading to false once authentication state is determined
      if (user && user.email) {
        setUserEmail(user.email.split("@")[0]); // Extracting the part before '@'
      }
    });
    return unsubscribe; // Cleanup function
  }, []);

  const isActive = (item) => {
    const currentPath = location.pathname;
    if (
      currentPath === item.path ||
      currentPath.startsWith(`${item.path}/admin`)
    ) {
      return "active"; // If URL matches or starts with /admin/, make the link active
    }

    if (item.submenu) {
      // Check if any of the submenu items' path is part of the current path
      const match = item.submenu.some((subItem) => {
        return currentPath.includes(subItem.path);
      });

      if (match) {
        return "active";
      }
    }

    return "";
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const totalPageCount = Object.values(pageCounts).reduce(
    (sum, count) => sum + count,
    0
  );

  const navItems = [
    { name: "HOME", path: "/" },
    {
      name: "OUR PROGRAMS",
      path: "/programs",
      submenu: [
        {
          name: "All Programs",
          path: "/programs",
          className: "header-category",
          icon: <i class="fa-solid fa-people-roof"></i>,
        },
        {
          name: "Newsletters",
          path: "/articles",
          className: "header-category",
          icon: <i class="fa-solid fa-newspaper"></i>,
        },
      ],
    },
    {
      name: "ABOUT US",
      path: "/about",
      submenu: [
        {
          name: "About Us",
          icon: <i className="fa-solid fa-book"></i>,
          className: "header-category",
          path: "/about",
        },
        {
          name: "Where We Work",
          icon: <i className="fa-solid fa-house"></i>,
          path: "/about#kibera",
        },
        {
          name: "Our Founders",
          icon: <i className="fa-solid fa-book-open-reader"></i>,
          path: "/about#founders",
        },
      ],
    },
    {
      name: "TEAM & CONTACT",
      path: "/contact",
      submenu: [
        {
          name: "Our Board",
          icon: <i className="fa-solid fa-users-line"></i>,
          path: "/contact#board",
        },
        {
          name: "Our Team",
          icon: <i className="fa-solid fa-people-group"></i>,
          path: "/contact#team",
        },
        {
          name: "Contact Us",
          icon: <i className="fa-regular fa-address-book"></i>,
          path: "/contact#contact",
        },
      ],
    },
    {
      name: "DONATE",
      path: "/donate",
      // submenu: [
      //   {
      //     name: "Donate",
      //     icon: <i className="fa-solid fa-users-line"></i>,
      //     path: "/donate",
      //   },
      //   {
      //     name: "Shop",
      //     icon: <i className="fa-solid fa-users-line"></i>,
      //     path: "/shop",
      //   },
      //   {
      //     name: "Sponsorship",
      //     icon: <i className="fa-solid fa-people-group"></i>,
      //     path: "/sponsorship",
      //   },
      // ],
    },
  ];

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUserData(null);
      navigate("/");
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };

  return (
    <div>
      <nav className="navbar-2" id="nav2" onClick={toggleMenu}>
        <span className="notranslate" style={{ userSelect: "none" }}>
          <span className="box-1-navbar-2">THE WATOTO LIBRARY</span>
        </span>
        <span className="icon">
          {isMenuOpen ? (
            <i className="fa fa-remove white-color" id="deactivate-menu"></i>
          ) : (
            <i className="fa fa-bars white-color" id="activate-menu"></i>
          )}
        </span>
      </nav>

      <ul className={`navigation ${isMenuOpen ? "mobile-open" : ""}`} id="nav">
        {navItems.map((item) => (
          <li key={item.name} className={`${isActive(item)} main-nav-item`}>
            <MyLink
              to={item.path}
              className="main-link"
              // onClick={(e) => isMobile && item.submenu && e.preventDefault()}
            >
              {item.name}
            </MyLink>
            {!isMobile && item.submenu && (
              <ul className="submenu">
                {item.submenu.map((subItem) => (
                  <li
                    key={subItem.name}
                    className={`${
                      subItem.className ? subItem.className : "submenu-item"
                    }`}
                  >
                    <MyLink to={subItem.path} className="submenu-link">
                      {subItem.icon}
                      <span>{subItem.name}</span>
                    </MyLink>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
        {userData &&
          (userData.role === "Staff" || userData.role === "Admin") && (
            <li className="intranet-styling">
              <MyLink to="/intranet" className="main-link white">
                <i class="fa-solid fa-chart-line"></i> INTRANET{" "}
                {totalPageCount > 0 && (
                  <>
                    <span className="request-count navbar">
                      <i class="fa-regular fa-bell"></i> {totalPageCount}
                    </span>
                  </>
                )}
              </MyLink>
            </li>
          )}

        <AdminSwitch isAdmin={isAdmin} />
        {userData && userData.role === "Sponsor" && (
          <li className="userData-info">
            <a onClick={handleLogout}>
              <u>Log out of {userData.phoneNumber} </u>
            </a>
          </li>
        )}

        <li>
          <MyLink to="/sponsorship" className="main-link">
            SPONSOR A CHILD
          </MyLink>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
