import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  query,
  where,
  getDoc,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage"; // Updated Firebase Storage imports
import { db, storage } from "../../../firebase"; // Firebase imports
import { useAuth } from "../../../AuthProvider";
import { v4 as uuidv4 } from "uuid"; // Unique ID for file uploads
import { format, subDays } from "date-fns"; // Add this import at the top for date formatting
import { EconomicsContext } from "./EconomicsContext";
import "./Economics.css";

const Economics = () => {
  const { userData, isAdmin } = useAuth(); // Use userData instead of currentUser
  const { requests, setRequests } = useContext(EconomicsContext);
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [isNewItem, setIsNewItem] = useState(false); // State to track if a new item is being created
  const [newRequest, setNewRequest] = useState({
    amount: "",
    item: "",
    paidFor: false,
    receipt: null,
    comment: "",
  });
  const [disapproveComment, setDisapproveComment] = useState({});
  const [showDisapproveInput, setShowDisapproveInput] = useState({});

  const [approvedAmounts, setApprovedAmounts] = useState({});
  const [receiptFiles, setReceiptFiles] = useState({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showOlderCompletedRequests, setShowOlderCompletedRequests] =
    useState(false);
  const [adminComments, setAdminComments] = useState({});

  useEffect(() => {
    if (!userData) return;

    // Retrieve cached data from localStorage
    const localData = localStorage.getItem("economicsData");
    const localTimestamp = localStorage.getItem("economicsDataTimestamp");

    if (localData) {
      // Load data from localStorage
      const storedRequests = JSON.parse(localData);
      setRequests(storedRequests);
    }

    // Set up the requests listener
    let requestQuery;
    if (isAdmin) {
      requestQuery = collection(db, "economics");
    } else {
      requestQuery = query(
        collection(db, "economics"),
        where("userId", "==", userData.email)
      );
    }

    const unsubscribeRequests = onSnapshot(requestQuery, (snapshot) => {
      const fetchedRequests = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort and set the requests state
      const sortedRequests = fetchedRequests.sort((a, b) => {
        if (a.createdAt && b.createdAt) {
          return b.createdAt.toDate() - a.createdAt.toDate();
        }
        return 0;
      });
      setRequests(sortedRequests);

      // Store data in localStorage
      localStorage.setItem("economicsData", JSON.stringify(sortedRequests));
      localStorage.setItem("economicsDataTimestamp", Date.now().toString());
    });

    // Cleanup subscriptions on unmount or when dependencies change
    return () => {
      if (unsubscribeRequests) unsubscribeRequests();
    };
  }, [userData, isAdmin]);

  const fourteenDaysAgo = subDays(new Date(), 14);

  const isCompletedRequest = (request) => {
    if (isAdmin) {
      return (
        request.status === "Completed" ||
        request.status === "Declined" ||
        (request.receiptUrl && !request.receiptApproved)
      );
    } else {
      // Include "Completed" and "Declined" requests for non-admin users
      return request.status === "Completed" || request.status === "Declined";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!userData) {
      setError("User not authenticated");
      setLoading(false);
      return;
    }

    const { amount, item, paidFor, receipt, comment } = newRequest;

    if (!amount || !item) {
      setError("Please fill in all required fields");
      setLoading(false);
      return;
    }

    try {
      let receiptUrl = null;

      if (paidFor && receipt) {
        const fileName = `receipts/${uuidv4()}`;
        const receiptRef = storageRef(storage, fileName);
        await uploadBytes(receiptRef, receipt);
        receiptUrl = await getDownloadURL(receiptRef);
      }

      const requestData = {
        userId: userData.email,
        userName: userData.name,
        amount: parseFloat(amount),
        item,
        paidFor,
        receiptUrl,
        comment: comment || "",
        status: "Awaiting approval",
        createdAt: serverTimestamp(),
      };

      // Generate a custom document ID based on the current date and time
      const currentDate = new Date();
      const customDocId = `${currentDate.getFullYear()}${String(
        currentDate.getMonth() + 1
      ).padStart(2, "0")}${String(currentDate.getDate()).padStart(
        2,
        "0"
      )}-${String(currentDate.getHours()).padStart(2, "0")}${String(
        currentDate.getMinutes()
      ).padStart(2, "0")}${String(currentDate.getSeconds()).padStart(2, "0")}`;

      // Add new request to Firestore with the custom document ID
      await setDoc(doc(db, "economics", customDocId), requestData);

      // Reset the form
      setNewRequest({
        amount: "",
        item: "",
        paidFor: false,
        receipt: null,
        comment: "",
      });
      setError(null);
    } catch (err) {
      console.error("Error submitting request:", err);
      setError("Error submitting request");
    } finally {
      setLoading(false);
    }
  };

  const handleApproval = async (id, approvedAmount, status, adminComment) => {
    const approvedAmountValue = parseFloat(approvedAmount);

    // Check if the amount is valid
    if (status === "Partially approved" && approvedAmountValue <= 0) {
      alert("Approved amount must be greater than 0.");
      return;
    }

    try {
      const requestRef = doc(db, "economics", id);
      await updateDoc(requestRef, {
        approvedAmount: approvedAmountValue,
        status,
        approvedBy: userData.name,
        adminComment: adminComment || "", // Store the admin comment
      });
    } catch (err) {
      console.error("Error approving request:", err);
    }
  };

  const handleReceiptFileChange = (e, requestId) => {
    if (e.target.files[0]) {
      setReceiptFiles({ ...receiptFiles, [requestId]: e.target.files[0] });
    }
  };

  const handleApproveReceipt = async (requestId) => {
    try {
      const requestRef = doc(db, "economics", requestId);
      await updateDoc(requestRef, {
        receiptApproved: true,
        receiptConfirmedBy: userData.name,
        receiptConfirmedOn: serverTimestamp(),
        status: "Completed",
      });
    } catch (err) {
      console.error("Error approving receipt:", err);
      alert("Error approving receipt.");
    }
  };

  const handleDisapproveReceipt = async (requestId) => {
    try {
      const comment = disapproveComment[requestId] || ""; // Optional comment

      const requestRef = doc(db, "economics", requestId);

      // Prepare update data with or without the comment
      const updateData = {
        status: "Receipt rejected",
        receiptUrl: null,
        receiptApproved: false,
        adminComment: comment,
      };

      // If `approvedBy` is not set, assign current user's name
      const requestDoc = await getDoc(requestRef);
      if (!requestDoc.data().approvedBy) {
        updateData.approvedBy = userData.name;
      }

      await updateDoc(requestRef, updateData);

      // Clear the comment input field after submission
      setDisapproveComment((prev) => ({ ...prev, [requestId]: "" }));
      setShowDisapproveInput((prev) => ({ ...prev, [requestId]: false }));
    } catch (err) {
      console.error("Error disapproving receipt:", err);
      alert("Error disapproving receipt.");
    }
  };

  const submitReceipt = async (requestId) => {
    const receipt = receiptFiles[requestId];
    if (!receipt) {
      alert("Please select a receipt file to upload.");
      return;
    }
    try {
      // Upload receipt to Firebase Storage
      const fileName = `receipts/${uuidv4()}`;
      const receiptRef = storageRef(storage, fileName);
      await uploadBytes(receiptRef, receipt);
      const receiptUrl = await getDownloadURL(receiptRef);

      // Get the current request data
      const requestRef = doc(db, "economics", requestId);
      const requestDoc = await getDoc(requestRef);

      if (requestDoc.exists()) {
        // Update the request with the new receipt URL and set status to "Pending review"
        await updateDoc(requestRef, {
          receiptUrl,
          status: "Pending review",
        });

        // Remove the receipt file from state
        const newReceiptFiles = { ...receiptFiles };
        delete newReceiptFiles[requestId];
        setReceiptFiles(newReceiptFiles);
      } else {
        console.error("Request not found");
      }
    } catch (err) {
      console.error("Error uploading receipt:", err);
      alert("Error uploading receipt.");
    }
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case "Approved":
        return {
          color: "#5cb85c",
          fontWeight: "bold",
        };
      case "Partially approved":
        return {
          color: "#f0ad4e",
          fontWeight: "bold",
        };
      case "Awaiting approval":
        return {
          color: "#f0ad4e",
          fontWeight: "bold",
        };
      case "Declined":
        return {
          color: "#d9534f",
        };
      case "Receipt rejected":
        return {
          color: "#d9534f",
        };
      case "Pending review":
        return {
          color: "#f0ad4e",
          fontWeight: "bold",
        };
      default:
        return {
          color: "#1ba0f2",
          fontWeight: "bold",
        };
    }
  };

  const activeRequests = requests.filter((request) => {
    if (isAdmin) {
      return (
        request.status === "Awaiting approval" ||
        request.status === "Approved" ||
        request.status === "Partially approved" ||
        request.status === "Receipt rejected"
      );
    } else {
      return (
        request.status === "Awaiting approval" ||
        request.status === "Approved" ||
        request.status === "Pending review" ||
        request.status === "Partially approved" ||
        request.status === "Receipt rejected"
      );
    }
  });

  const getInitials = (name) => {
    if (!name) return "";
    return name
      .split(" ")
      .map((part) => part[0])
      .join("")
      .toUpperCase();
  };

  const getDateFromTimestamp = (timestamp) => {
    if (!timestamp) return null;
    if (timestamp.toDate) {
      return timestamp.toDate();
    } else if (timestamp.seconds) {
      // If it's a Firebase Timestamp object without methods (due to JSON parsing)
      return new Date(timestamp.seconds * 1000);
    } else if (typeof timestamp === "string" || timestamp instanceof Date) {
      // If it's a date string or Date object
      return new Date(timestamp);
    } else {
      return null; // Unable to parse
    }
  };

  const completedRequests = requests.filter((request) => {
    if (!isCompletedRequest(request)) return false;

    if (request.receiptUrl && !request.receiptApproved) {
      return true; // Always show requests with an unapproved receipt
    }

    if (showOlderCompletedRequests) {
      return true; // Show all completed requests if viewing older ones
    } else if (request.createdAt) {
      const requestDate = getDateFromTimestamp(request.createdAt);
      return requestDate >= fourteenDaysAgo; // Only show recent requests
    } else {
      return false; // Exclude requests without `createdAt` if showing recent only
    }
  });

  const olderCompletedRequestsExist = requests.some((request) => {
    if (!isCompletedRequest(request)) return false;

    if (request.createdAt) {
      const requestDate = getDateFromTimestamp(request.createdAt);
      return requestDate < fourteenDaysAgo; // Only detect older requests
    } else {
      return false; // Exclude requests without `createdAt`
    }
  });

  const renderUserView = () => (
    <>
      <h3>Create Budget Request</h3>
      <div className="create-request-table">
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <span>
              Item:{" "}
              <input
                type="text"
                placeholder="Enter item name"
                maxLength={25}
                value={newRequest.item}
                onChange={(e) =>
                  setNewRequest({ ...newRequest, item: e.target.value })
                }
                required
              />
            </span>
            <span>
              Amount: KES{" "}
              <input
                type="number"
                value={newRequest.amount}
                onChange={(e) =>
                  setNewRequest({ ...newRequest, amount: e.target.value })
                }
                required
              />
            </span>

            <button type="submit" disabled={loading}>
              {loading ? "Submitting..." : "Submit Request"}
            </button>
          </div>

          <span className="full-width">
            Comment:{" "}
            <input
              type="text"
              className="budget-request-comment-input"
              placeholder="Add a comment (optional)"
              value={newRequest.comment}
              onChange={(e) =>
                setNewRequest({ ...newRequest, comment: e.target.value })
              }
            />
          </span>

          {error && <p>{error}</p>}
        </form>
      </div>
      <br />

      {activeRequests.length > 0 ? (
        <>
          <h3>Active Requests</h3>
          <table className="economics-table">
            <thead>
              <tr>
                <th>Requested</th>

                <th>Item</th>
                <th>Requested</th>
                <th>Approved</th>
                <th>Status</th>
                <th>Receipt Upload</th>
              </tr>
            </thead>
            <tbody>
              {activeRequests.map((request, index) => {
                // Determine the background color based on the row index (alternating colors)
                const rowColor = index % 2 === 0 ? "#f2f2f2" : "#ffffff";

                return (
                  <React.Fragment key={request.id}>
                    <tr style={{ backgroundColor: rowColor }}>
                      <td>
                        {request.createdAt?.toDate ? (
                          <span>
                            {format(
                              request.createdAt.toDate(),
                              "dd/MM/yyyy HH:mm"
                            )}
                          </span>
                        ) : (
                          "N/A"
                        )}
                      </td>

                      <td>{request.item}</td>
                      <td>{request.amount}</td>
                      <td>
                        {request.approvedAmount !== undefined
                          ? `${request.approvedAmount}`
                          : "N/A"}
                      </td>
                      <td>
                        {request.status === "Pending review" &&
                        !request.receiptApproved ? (
                          <span style={getStatusStyle("Pending review")}>
                            Pending review
                          </span>
                        ) : request.adminComment &&
                          request.adminComment.includes("Receipt rejected") ? (
                          <span style={getStatusStyle("Receipt rejected")}>
                            Receipt rejected
                          </span>
                        ) : (
                          <span style={getStatusStyle(request.status)}>
                            {request.status}
                          </span>
                        )}
                        <br />
                        {(request.status === "Rejected" ||
                          request.status === "Receipt rejected") && (
                          <span className="small-font">
                            {request.approvedBy && ` by ${request.approvedBy}`}
                          </span>
                        )}
                      </td>

                      <td>
                        <td>
                          {request.receiptUrl ? (
                            <p>Receipt uploaded</p>
                          ) : (
                            // Allow receipt upload regardless of approval status
                            <div className="orange-border">
                              <input
                                type="file"
                                onChange={(e) =>
                                  handleReceiptFileChange(e, request.id)
                                }
                              />
                              <button onClick={() => submitReceipt(request.id)}>
                                Upload Receipt
                              </button>
                            </div>
                          )}
                        </td>
                      </td>
                    </tr>
                    <tr
                      style={{ backgroundColor: rowColor }}
                      className="comments-row"
                    >
                      {request.adminComment ? (
                        <>
                          {request.comment ? (
                            <td colSpan="5">
                              <i class="fa-regular fa-comment"></i>{" "}
                              <strong>{request.userName}</strong>: "
                              <span title={request.comment}>
                                {request.comment}
                              </span>
                              "
                            </td>
                          ) : (
                            <td colSpan="5"></td>
                          )}
                          <td colSpan="1">
                            <i class="fa-regular fa-comment"></i>{" "}
                            <strong>{request.approvedBy}</strong>: "
                            <span title={request.adminComment}>
                              {request.adminComment}
                            </span>
                            "
                          </td>
                        </>
                      ) : (
                        <>
                          {request.comment && (
                            <td colSpan="6">
                              <i class="fa-regular fa-comment"></i>{" "}
                              <strong>{request.userName}</strong>: "
                              <span title={request.comment}>
                                {request.comment}
                              </span>
                              "
                            </td>
                          )}
                        </>
                      )}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <p>No active requests.</p>
      )}
      <br />
      <h3>Completed Requests</h3>
      {olderCompletedRequestsExist && (
        <button
          onClick={() =>
            setShowOlderCompletedRequests(!showOlderCompletedRequests)
          }
        >
          {showOlderCompletedRequests
            ? "Hide Older Completed Requests"
            : "Show Completed Requests Older Than 14 Days"}
        </button>
      )}
      {completedRequests.length >= 0 && (
        <table className="economics-table">
          <thead>
            <tr>
              <th>Requested</th>
              <th>Item</th>
              <th>Requested</th>
              <th>Approved</th>
              <th>Status</th>
              <th>Receipt</th>
            </tr>
          </thead>
          <tbody>
            {completedRequests.map((request) => (
              <React.Fragment key={request.id}>
                <tr>
                  <td>
                    {request.createdAt?.toDate ? (
                      <span>
                        {format(request.createdAt.toDate(), "dd/MM/yyyy HH:mm")}
                      </span>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>{request.item}</td>
                  <td>{request.amount}</td>
                  <td>
                    {request.approvedAmount !== undefined
                      ? `${request.approvedAmount}`
                      : "N/A"}
                  </td>
                  <td>
                    {request.status === "Pending review" &&
                    !request.receiptApproved ? (
                      <span style={getStatusStyle("Pending review")}>
                        Pending review
                      </span>
                    ) : request.adminComment &&
                      request.adminComment.includes("Receipt rejected") ? (
                      <span style={getStatusStyle("Receipt rejected")}>
                        Receipt rejected
                      </span>
                    ) : (
                      <span style={getStatusStyle(request.status)}>
                        {request.status}
                      </span>
                    )}
                    <br />
                    {(request.status === "Rejected" ||
                      request.status === "Receipt rejected" ||
                      request.status === "Declined") && (
                      <span className="small-font">
                        {request.approvedBy && ` by ${request.approvedBy}`}
                      </span>
                    )}
                  </td>

                  <td>
                    {request.receiptUrl && (
                      <u>
                        <a
                          href={request.receiptUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Receipt
                        </a>
                      </u>
                    )}{" "}
                    {request.receiptApproved &&
                    request.receiptConfirmedBy &&
                    request.receiptConfirmedOn ? (
                      <span className="confirmed-receipt grey">
                        <i className="fa-regular fa-circle-check"></i>{" "}
                        {getInitials(request.receiptConfirmedBy)}{" "}
                        {format(
                          getDateFromTimestamp(request.receiptConfirmedOn),
                          "dd/MM/yyyy HH:mm"
                        )}
                      </span>
                    ) : request.status === "Declined" ? (
                      ""
                    ) : (
                      <span className="confirmed-receipt">
                        <i className="fa-solid fa-magnifying-glass"></i> To be
                        reviewed
                      </span>
                    )}
                  </td>
                </tr>
                {request.status === "Declined" && request.adminComment ? (
                  <tr className="comments-row">
                    <td colSpan="6">
                      <i class="fa-regular fa-comment"></i>{" "}
                      <strong>{request.userName}</strong>: "
                      {request.adminComment}"
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            ))}
            {completedRequests.length == 0 && (
              <td colspan="6">No completed requests in the last 14 days.</td>
            )}
          </tbody>
        </table>
      )}
    </>
  );

  const renderAdminView = () => (
    <div>
      <br />

      {activeRequests.length > 0 ? (
        <>
          {" "}
          <h3>Active Requests</h3>
          <table className="economics-table">
            <thead>
              <tr>
                <th>Requested</th>
                <th>Requested by</th>
                <th>Item</th>
                <th>Requested</th>
                <th>Approved</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {activeRequests.map((request, index) => {
                // Determine the background color based on the row index (alternating colors)
                const rowColor = index % 2 === 0 ? "#ffffff" : "#f2f2f2";

                return (
                  <React.Fragment key={request.id}>
                    <tr style={{ backgroundColor: rowColor }}>
                      <td>
                        {request.createdAt ? (
                          <span>
                            {format(
                              getDateFromTimestamp(request.createdAt),
                              "dd/MM/yyyy HH:mm"
                            )}
                          </span>
                        ) : (
                          "N/A"
                        )}
                      </td>

                      <td>{request.userName || request.userId}</td>
                      <td>{request.item}</td>
                      <td>{request.amount}</td>
                      <td>{request.approvedAmount || "N/A"}</td>
                      <td>
                        {request.status === "Pending review" &&
                        !request.receiptApproved ? (
                          <>
                            <span style={getStatusStyle("Pending review")}>
                              Pending review
                            </span>
                            <div className="receipt-review-actions">
                              <button
                                onClick={() => handleApproveReceipt(request.id)}
                                className="approve-receipt-button"
                              >
                                Approve Receipt
                              </button>
                              <button
                                onClick={() =>
                                  setShowDisapproveInput((prev) => ({
                                    ...prev,
                                    [request.id]: !prev[request.id], // Toggle the visibility for specific request
                                  }))
                                }
                                className="disapprove-receipt-button"
                              >
                                Disapprove Receipt
                              </button>
                              {showDisapproveInput[request.id] && (
                                <div className="disapprove-comment-section">
                                  <input
                                    type="text"
                                    placeholder="Enter reason for rejection"
                                    value={disapproveComment[request.id] || ""}
                                    onChange={(e) =>
                                      setDisapproveComment((prev) => ({
                                        ...prev,
                                        [request.id]: e.target.value,
                                      }))
                                    }
                                  />
                                  <button
                                    onClick={() => {
                                      handleDisapproveReceipt(request.id); // Call the disapproval only when "Confirm" is clicked
                                    }}
                                    className="confirm-disapprove-button"
                                  >
                                    Confirm
                                  </button>
                                </div>
                              )}
                            </div>
                          </>
                        ) : request.adminComment &&
                          request.adminComment.includes("Receipt rejected") ? (
                          <span style={getStatusStyle("Receipt rejected")}>
                            Receipt rejected
                          </span>
                        ) : (
                          <span style={getStatusStyle(request.status)}>
                            {request.status}
                          </span>
                        )}
                        <br />
                        {(request.status === "Rejected" ||
                          request.status === "Receipt rejected") && (
                          <span className="small-font">
                            {request.approvedBy && ` by ${request.approvedBy}`}
                          </span>
                        )}
                      </td>

                      <td>
                        {request.status === "Approved" ||
                        request.status === "Partially approved" ? (
                          <p>Awaiting user receipt</p>
                        ) : (
                          <>
                            {request.status !== "Receipt rejected" && (
                              <div className="approval-actions">
                                <div className="approval-buttons">
                                  <button
                                    className="approval-button approve"
                                    onClick={() =>
                                      handleApproval(
                                        request.id,
                                        request.amount,
                                        "Approved",
                                        adminComments[request.id]
                                      )
                                    }
                                  >
                                    Fully approve
                                  </button>
                                  <button
                                    className="approval-button partial-approve"
                                    onClick={() =>
                                      handleApproval(
                                        request.id,
                                        approvedAmounts[request.id] || 0,
                                        "Partially approved",
                                        adminComments[request.id]
                                      )
                                    }
                                  >
                                    Partially Approve
                                  </button>
                                  <input
                                    type="number"
                                    className="approval-input partially"
                                    placeholder="Amount"
                                    value={approvedAmounts[request.id] || ""}
                                    onChange={(e) =>
                                      setApprovedAmounts({
                                        ...approvedAmounts,
                                        [request.id]: e.target.value,
                                      })
                                    }
                                  />
                                  <button
                                    className="approval-button decline"
                                    onClick={() =>
                                      handleApproval(
                                        request.id,
                                        0,
                                        "Declined",
                                        adminComments[request.id]
                                      )
                                    }
                                  >
                                    Decline
                                  </button>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr
                      style={{ backgroundColor: rowColor }}
                      className="comments-row"
                    >
                      <td colSpan="6">
                        {request.comment && (
                          <>
                            <i class="fa-regular fa-comment"></i>{" "}
                            <strong>{request.userName}</strong>: "
                            {request.comment}"
                          </>
                        )}
                      </td>

                      {request.status === "Awaiting approval" ? (
                        <td colSpan="1">
                          <input
                            type="text"
                            placeholder="Add a comment (optional)"
                            className="admin-comment-input"
                            value={adminComments[request.id] || ""}
                            onChange={(e) =>
                              setAdminComments({
                                ...adminComments,
                                [request.id]: e.target.value,
                              })
                            }
                          />
                        </td>
                      ) : (
                        <>
                          <td colSpan="1">
                            {request.comment && (
                              <>
                                <i class="fa-regular fa-comment"></i>{" "}
                                <strong>{request.approvedBy}</strong>: "
                                {request.adminComment}"
                              </>
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        <p>No active requests.</p>
      )}
      <br />
      <h3>Completed Requests</h3>
      {olderCompletedRequestsExist && (
        <button
          onClick={() =>
            setShowOlderCompletedRequests(!showOlderCompletedRequests)
          }
        >
          {showOlderCompletedRequests
            ? "Hide Older Completed Requests"
            : "Show Completed Requests Older Than 14 Days"}
        </button>
      )}
      {completedRequests.length >= 0 && (
        <table className="economics-table">
          <thead>
            <tr>
              <th>Requested</th>
              <th>Requested by</th>
              <th>Item</th>
              <th>Requested</th>
              <th>Approved</th>
              <th>Status</th>
              <th>Receipt</th>
            </tr>
          </thead>
          <tbody>
            {completedRequests.map((request) => (
              <React.Fragment key={request.id}>
                <tr>
                  <td>
                    {request.createdAt ? (
                      <span>
                        {format(
                          getDateFromTimestamp(request.createdAt),
                          "dd/MM/yyyy HH:mm"
                        )}
                      </span>
                    ) : (
                      "N/A"
                    )}
                  </td>
                  <td>{request.userName || request.userId}</td>
                  <td>{request.item}</td>
                  <td>{request.amount}</td>
                  <td>{request.approvedAmount || "N/A"}</td>
                  <td>
                    <span style={getStatusStyle(request.status)}>
                      {request.status}
                    </span>
                    <br />
                    {(request.status === "Rejected" ||
                      request.status === "Receipt rejected" ||
                      request.status === "Declined") && (
                      <span className="small-font">
                        {request.approvedBy && ` by ${request.approvedBy}`}
                      </span>
                    )}
                  </td>
                  <td>
                    {request.receiptUrl && (
                      <u>
                        <a
                          href={request.receiptUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Receipt
                        </a>
                      </u>
                    )}{" "}
                    {request.status === "Pending review" &&
                      !request.receiptApproved && (
                        <>
                          <a onClick={() => handleApproveReceipt(request.id)}>
                            <i class="fa-solid fa-check"></i>
                          </a>
                          <a
                            onClick={() => handleDisapproveReceipt(request.id)}
                          >
                            <i class="fa-solid fa-x"></i>
                          </a>{" "}
                          <input
                            type="text"
                            placeholder="Comment"
                            className="disapprove-comment-input"
                            value={disapproveComment[request.id] || ""}
                            onChange={(e) =>
                              setDisapproveComment((prev) => ({
                                ...prev,
                                [request.id]: e.target.value, // Set comment for this request
                              }))
                            }
                          />
                        </>
                      )}{" "}
                    {request.receiptApproved &&
                      request.receiptConfirmedBy &&
                      request.receiptConfirmedOn && (
                        <span className="confirmed-receipt">
                          <i class="fa-regular fa-circle-check"></i>{" "}
                          {getInitials(request.receiptConfirmedBy)}{" "}
                          {format(
                            getDateFromTimestamp(request.receiptConfirmedOn),
                            "dd/MM/yyyy HH:mm"
                          )}
                        </span>
                      )}
                  </td>
                </tr>
                {request.status === "Declined" && request.adminComment ? (
                  <tr className="comments-row">
                    <td colSpan="7">
                      <i class="fa-regular fa-comment"></i>{" "}
                      <strong>{request.userName}</strong>: "
                      {request.adminComment}"
                    </td>
                  </tr>
                ) : null}
              </React.Fragment>
            ))}
            {completedRequests.length == 0 && (
              <td colSpan="7">No completed requests in the last 14 days.</td>
            )}
          </tbody>
        </table>
      )}
    </div>
  );

  if (!requests) {
    return <div>Loading...</div>;
  }

  return (
    <div className="row2" id="flex">
      <div className="main">
        <div className="economics-page">
          <div className="economics-header">
            <h2>Economics</h2>
          </div>
          {isAdmin ? renderAdminView() : renderUserView()}
        </div>
      </div>
    </div>
  );
};

export default Economics;
