import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./AdminSwitch.css"; // Make sure this is the correct path to your CSS file
import { useAuth } from "../../AuthProvider";

const SwitchView = () => {
  const { isAdmin, userData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const isAdminView = location.pathname.startsWith("/admin"); // Check if the URL contains "/admin"

  useEffect(() => {
    if (isAdmin || (userData && userData.role === "Admin")) {
      const adminView = localStorage.getItem("adminView");
      const currentPath = location.pathname;

      console.log("Admin view: ", adminView);
      if (adminView === "true" && !currentPath.startsWith("/admin")) {
        console.log("Navigating to admin view");
        navigate(`/admin${currentPath}`);
      } else if (adminView === "false" && currentPath.startsWith("/admin")) {
        navigate(currentPath.substring(6));
        console.log("Navigating to user view");
      }
    } else {
      navigate(location.pathname);
    }
  }, [isAdmin, userData, location.pathname]);

  const toggleAdminView = () => {
    if (isAdmin || (userData && userData.role === "Admin")) {
      const currentPath = location.pathname;
      let newPath = "";

      if (currentPath.startsWith("/admin")) {
        // If currently in admin view, switch to user view by removing "/admin"
        newPath = currentPath.substring(6); // Removes "/admin" from the path
      } else {
        // If not in admin view, switch to admin view by adding "/admin"
        newPath = `/admin${currentPath}`;
      }

      localStorage.setItem("adminView", !isAdminView); // Store the current view in local storage
      navigate(newPath); // Navigate to the new path
    }
  };

  return (
    <>
      {(isAdmin || (userData && userData.role === "Admin")) && (
        <div className="switch-view-container">
          <label className="switch">
            <input
              type="checkbox"
              checked={isAdminView} // Use isAdminView to determine initial state of the slider
              onChange={toggleAdminView} // Toggles the view based on current path
            />
            <span className="slider round"></span> {/* Visual slider element */}
          </label>
          <span className="switch-label">
            {isAdminView ? "ADMIN" : "USER"}{" "}
            {/* Display "Admin View" or "User View" based on isAdminView */}
          </span>
        </div>
      )}
    </>
  );
};

export default SwitchView;
