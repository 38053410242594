// PageCountsContext.js
import React, { createContext, useState, useEffect } from "react";
import { useAuth } from "../../../AuthProvider";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";

export const PageCountsContext = createContext();

export const PageCountsProvider = ({ children }) => {
  const { isAdmin, userData } = useAuth();
  const [pageCounts, setPageCounts] = useState({});

  useEffect(() => {
    const fetchAllCounts = async () => {
      // Add this check to ensure userData is available
      if (!userData || !userData.email) {
        return; // Exit the function if userData is not available yet
      }

      try {
        // Define queries
        let economicsQuery, reportsQuery;

        // Fetch economic data for user/admin
        if (isAdmin) {
          economicsQuery = collection(db, "economics");
          reportsQuery = collection(db, "reports");
        } else {
          economicsQuery = query(
            collection(db, "economics"),
            where("userId", "==", userData.email)
          );
          reportsQuery = query(
            collection(db, "reports"),
            where("userId", "==", userData.email)
          );
        }

        const [economicsSnapshot, reportsSnapshot] = await Promise.all([
          getDocs(economicsQuery),
          getDocs(reportsQuery),
        ]);

        const economicsData = economicsSnapshot.docs.map((doc) => doc.data());
        const reportsData = reportsSnapshot.docs.map((doc) => doc.data());

        const newPageCounts = {};

        if (isAdmin) {
          const economicsCount = economicsData.filter((request) => {
            return (
              request.status === "Awaiting approval" ||
              request.status === "Pending review" ||
              request.status === "Receipt rejected"
            );
          }).length;
          newPageCounts["economics"] = economicsCount;
        } else {
          const economicsCount = economicsData.filter((request) => {
            return (
              request.status === "Pending review" ||
              request.status === "Receipt rejected" ||
              ((request.status === "Approved" ||
                request.status === "Partially approved") &&
                !request.receiptUrl)
            );
          }).length;
          newPageCounts["economics"] = economicsCount;
        }

        // Calculate reports counts
        if (isAdmin) {
          const toReview = reportsData.filter(
            (report) => report.status === "submitted"
          ).length;
          newPageCounts["reports"] = toReview;
        } else {
          const reportExistsForCurrentWeek = reportsData.some(
            (report) => report.week === `Week ${getWeekNumber(new Date())}`
          );

          const toCreate =
            canCreateReport() && !reportExistsForCurrentWeek ? 1 : 0;

          const toConfirm = reportsData.filter(
            (report) => report.status === "awaitingRead"
          ).length;

          newPageCounts["reports"] = toCreate + toConfirm;
        }

        // Update the pageCounts state
        setPageCounts(newPageCounts);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAllCounts();
  }, [isAdmin, userData]);

  // Include the helper functions
  const getWeekNumber = (date) => {
    const startDate = new Date(date.getFullYear(), 0, 1);
    const startDayOfWeek = startDate.getDay();
    const dayOfYear =
      Math.floor((date - startDate) / (24 * 60 * 60 * 1000)) + 1;

    const weekNumber = Math.ceil((dayOfYear + (startDayOfWeek - 1)) / 7);
    return weekNumber;
  };

  const canCreateReport = () => {
    const today = new Date().getDay();
    return today >= 4 || today === 0;
  };

  return (
    <PageCountsContext.Provider value={pageCounts}>
      {children}
    </PageCountsContext.Provider>
  );
};
